import { handleActions } from "redux-actions";
import { IEarningsRecordsModel } from "../../interfaces";
import { EarningsActions } from "../actions";
import { EarningsRecordsInitialState } from "../states";

export const earningsRecordReducer = handleActions<IEarningsRecordsModel, IEarningsRecordsModel>(
  {
    [EarningsActions.TOGGLE_LOADER]: (
      state = EarningsRecordsInitialState,
      action
    ): IEarningsRecordsModel => ({
      ...state,
      isLoading: true,
    }),
    [EarningsActions.FETCH_EARNINGS_FAILED]: (
      state = EarningsRecordsInitialState,
      action
    ): IEarningsRecordsModel => ({
      ...state,
      isLoading: false,
      data: [],
    }),
    [EarningsActions.FETCH_EARNINGS_SUCCESS]: (
      state = EarningsRecordsInitialState,
      action
    ): IEarningsRecordsModel => ({
      ...state,
      isLoading: false,
      totalRecords: action.payload.totalRecords,
      data: action.payload.data,
    }),
  },
  EarningsRecordsInitialState
);
