import { PostTypeInitialState } from "./../states";
import { handleActions } from "redux-actions";
import { IPostTypesModel } from "../../interfaces";
import { PostTypeActions } from "../actions";

export const postTypesReducer = handleActions<IPostTypesModel, IPostTypesModel>(
  {
    [PostTypeActions.GET_POST_TYPES]: (
      state = PostTypeInitialState,
      action
    ): IPostTypesModel => ({
      isLoading: true,
      data: [],
    }),
    [PostTypeActions.POST_TYPES_FAILED]: (
      state = PostTypeInitialState,
      action
    ): IPostTypesModel => ({
      isLoading: false,
      data: [],
    }),
    [PostTypeActions.POST_TYPES_SUCCESS]: (
      state = PostTypeInitialState,
      action
    ): IPostTypesModel => ({
      isLoading: false,
      data: action.payload.data,
    }),
  },
  PostTypeInitialState
);
