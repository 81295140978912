import { createAction } from "redux-actions";

export enum PayoutActions {
  REQUEST_CREATE_PAYOUT = "Request to create payout!",
  PAYOUT_SUCCESS = "Payout success!",
  PAYOUT_FAILED = "Payout failed!",
  TOGGLE_PAYOUT_LOADER = "Toggle payout loader",
  REQUEST_PAYOUT_LIST = "Request to fetch payouts!",
  PAYOUT_LIST_SUCCESS = "Payout list success!",
  PAYOUT_LIST_FAILED = "Payout list failed!",
}

export const requestCreatePayout = createAction(
  PayoutActions.REQUEST_CREATE_PAYOUT
);
export const payoutSuccess = createAction(
  PayoutActions.PAYOUT_SUCCESS
);
export const payoutFailed = createAction(
  PayoutActions.PAYOUT_FAILED
);

export const requestPayoutsList = createAction(
  PayoutActions.REQUEST_PAYOUT_LIST
);
export const payoutListSuccess = createAction(
  PayoutActions.PAYOUT_LIST_SUCCESS
);
export const payoutListFailed = createAction(
  PayoutActions.PAYOUT_LIST_FAILED
);