import { ValidationError } from "yup";
import { object } from "dot-object";

export const getValidationErrors = (error: ValidationError): any => {
  let message: any = {};
  error.inner.forEach((err) => {
    if (!message[err.path]) {
      message[err.path] = err.message;
    }
  });
  return object(message);
};
