import { InitialToastrState } from "./../states";
import { handleActions } from "redux-actions";
import { IToastrModel } from "../../interfaces";
import { ToastrActions } from "../actions";

export const toastrReducer = handleActions<IToastrModel, IToastrModel>(
  {
    [ToastrActions.SHOW_TOASTR]: (
      state = InitialToastrState,
      action
    ): IToastrModel => ({
      ...action.payload,
    }),
    [ToastrActions.HIDE_TOASTR]: (
      state = InitialToastrState,
      action
    ): IToastrModel => ({
      ...InitialToastrState,
    }),
  },
  InitialToastrState
);
