import { Logic, createLogic } from "redux-logic";
import {
  DashboardActions,
  togglePostViewsLoader,
  totalPostViewFailed,
  totalPostViewsSuccess,
  toggleEarningsLoader,
  totalEarningsSuccess,
  totalEarningsFailed,
  totalPostsSuccess,
  togglePostsAnalyticsLoader,
  totalPostsFailed,
  toggleAvailableBalanceAnalyticsLoader,
  totalAvailableBalanceSuccess,
  totalAvailableBalanceFailed,
} from "../actions";
import { ApiHelper } from "../../utills/helpers";
import { ApiRoutes } from "../../utills/constants/ApiRoutes";
/**
 *
 */
const getPostViewsLogic = createLogic({
  type: DashboardActions.GET_TOTAL_POST_VIEWS,
  process: async (_, dispatch, done) => {
    dispatch(
      togglePostViewsLoader({
        isLoading: true,
      })
    );
    const { isError, data } = await new ApiHelper().FetchFromServer(
      ApiRoutes.POST_VIEWS_ANALYTICS.SERVICE,
      ApiRoutes.POST_VIEWS_ANALYTICS.ENDPOINT,
      ApiRoutes.POST_VIEWS_ANALYTICS.METHOD,
      ApiRoutes.POST_VIEWS_ANALYTICS.AUTHENTICATE,
    );

    if (isError) {
      dispatch(totalPostViewFailed());
      done();
      return;
    }
    dispatch(
      totalPostViewsSuccess({
        data,
      })
    );
    done();
  },
});

/**
 * Fecth earnings analytics
 */
const getEarningsLogic = createLogic({
  type: DashboardActions.GET_TOTAL_EARNINGS,
  process: async (_, dispatch, done) => {
    dispatch(
      toggleEarningsLoader({
        isLoading: true,
      })
    );
    const { isError, data } = await new ApiHelper().FetchFromServer(
      ApiRoutes.EARNINGS_ANALYTICS.SERVICE,
      ApiRoutes.EARNINGS_ANALYTICS.ENDPOINT,
      ApiRoutes.EARNINGS_ANALYTICS.METHOD,
      ApiRoutes.EARNINGS_ANALYTICS.AUTHENTICATE,
    );

    if (isError) {
      dispatch(totalEarningsFailed());
      done();
      return;
    }
    dispatch(
      totalEarningsSuccess({
        data,
      })
    );
    done();
  },
});

/**
 * Fecth posts analytics
 */
const getPostsAnalyticsLogic = createLogic({
  type: DashboardActions.GET_TOTAL_EARNINGS,
  process: async (_, dispatch, done) => {
    dispatch(
      togglePostsAnalyticsLoader({
        isLoading: true,
      })
    );
    const { isError, data } = await new ApiHelper().FetchFromServer(
      ApiRoutes.POSTS_ANALYTICS.SERVICE,
      ApiRoutes.POSTS_ANALYTICS.ENDPOINT,
      ApiRoutes.POSTS_ANALYTICS.METHOD,
      ApiRoutes.POSTS_ANALYTICS.AUTHENTICATE,
    );

    if (isError) {
      dispatch(totalPostsFailed());
      done();
      return;
    }
    dispatch(
      totalPostsSuccess({
        data,
      })
    );
    done();
  },
});

/**
 * Fecth available balance
 */
const getAvailableBalanceLogic = createLogic({
  type: DashboardActions.GET_TOTAL_AVAILABLE_BALANCE,
  process: async (_, dispatch, done) => {
    dispatch(
      toggleAvailableBalanceAnalyticsLoader({
        isLoading: true,
      })
    );
    const { isError, data } = await new ApiHelper().FetchFromServer(
      ApiRoutes.AVAILABLE_BALANCE_ANALYTICS.SERVICE,
      ApiRoutes.AVAILABLE_BALANCE_ANALYTICS.ENDPOINT,
      ApiRoutes.AVAILABLE_BALANCE_ANALYTICS.METHOD,
      ApiRoutes.AVAILABLE_BALANCE_ANALYTICS.AUTHENTICATE,
    );

    if (isError) {
      dispatch(totalAvailableBalanceFailed());
      done();
      return;
    }
    dispatch(
      totalAvailableBalanceSuccess({
        data,
      })
    );
    done();
  },
});

export const DashboardLogics: Logic[] = [getPostViewsLogic as Logic, getEarningsLogic as Logic, getPostsAnalyticsLogic as Logic, getAvailableBalanceLogic as Logic];
