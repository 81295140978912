import { createLogic, Logic } from "redux-logic";
import {
  ProfileActions,
  showToastr,
  profileDetailsFailed,
  profileDetailsSuccess,
} from "../actions";
import { ApiHelper } from "../../utills/helpers";
import { ApiRoutes } from "../../utills/constants/ApiRoutes";

/**
 *
 */
const getProfileDetails = createLogic({
  type: ProfileActions.REQUEST_PROFILE_DETAILS,
  process: async (action, dispatch, done) => {
    const { data, isError, messages } = await new ApiHelper().FetchFromServer(
      ApiRoutes.GET_PROFILE_DETAILS.SERVICE,
      ApiRoutes.GET_PROFILE_DETAILS.ENDPOINT,
      ApiRoutes.GET_PROFILE_DETAILS.METHOD,
      ApiRoutes.GET_PROFILE_DETAILS.AUTHENTICATE
    );
    if (isError) {
      dispatch(profileDetailsFailed());
      dispatch(
        showToastr({
          isOpen: true,
          message: messages[0],
          type: "error",
        })
      );
      done();
      return;
    }
    dispatch(profileDetailsSuccess({ details: data }));
    done();
  },
});
/**
 *
 */
const updateProfileDetails = createLogic({
  type: ProfileActions.REQUEST_UPDATE_PROFILE,
  process: async ({ action }, dispatch, done) => {
    const payload = (action as any).payload;
    const formData = new FormData();
    for (const key in payload) {
      if (payload.hasOwnProperty(key)) {
        const element = payload[key];
        formData.append(key, element);
      }
    }
    if (!payload.profileImage) {
      formData.append("profileImage", "");
    }
    const { data, isError, messages } = await new ApiHelper().FetchFromServer(
      ApiRoutes.UPDATE_PROFILE_DETAILS.SERVICE,
      ApiRoutes.UPDATE_PROFILE_DETAILS.ENDPOINT,
      ApiRoutes.UPDATE_PROFILE_DETAILS.METHOD,
      ApiRoutes.UPDATE_PROFILE_DETAILS.AUTHENTICATE,
      undefined,
      formData,
      {
        headers: {
          "Content-Type": false,
        },
      }
    );
    if (isError) {
      dispatch(profileDetailsFailed());
      dispatch(
        showToastr({
          isOpen: true,
          message: messages[0],
          type: "error",
        })
      );
      done();
      return;
    }
    dispatch(profileDetailsSuccess({ details: data.data }));
    dispatch(
      showToastr({
        isOpen: true,
        message: "Profile details updated successfully!",
        type: "success",
      })
    );
    done();
  },
});
/**
 *
 */
export const profileLogics: Logic[] = [
  getProfileDetails as Logic,
  updateProfileDetails as Logic,
];
