import { createLogic, Logic } from "redux-logic";
import {
  showToastr,
  CategoryActions,
  categoryListFailed,
  categoryListSuccess,
} from "../actions";
import { ApiHelper } from "../../utills/helpers";
import { ApiRoutes } from "../../utills/constants/ApiRoutes";

const getCategories: any = createLogic({
  type: CategoryActions.GET_CATEGORIES,
  latest: true,
  async process({ action }, dispatch, done) {
    const payload = (action as any).payload;
    const { data, isError, messages } = await new ApiHelper().FetchFromServer(
      ApiRoutes.CATEGORY_BY_POST_TYPES.SERVICE,
      ApiRoutes.CATEGORY_BY_POST_TYPES.ENDPOINT.replace(":id", payload.id),
      ApiRoutes.CATEGORY_BY_POST_TYPES.METHOD,
      ApiRoutes.CATEGORY_BY_POST_TYPES.AUTHENTICATE
    );
    if (isError) {
      dispatch(categoryListFailed());
      dispatch(
        showToastr({
          isOpen: true,
          message: messages[0],
          type: "error",
        })
      );
      done();
      return;
    }
    dispatch(
      categoryListSuccess({
        data,
      })
    );
    done();
  },
});

export const CategoryLogics: Logic[] = [getCategories];
