import { createLogic, Logic } from "redux-logic";
import cookie from "js-cookie";
import {
  showToastr,
  redirectTo,
  SignupActionTypes,
  userSignupFailed,
  userSignupSuccess,
} from "../actions";
import { RouteURLs } from "../../routes/routes";
import { ApiHelper } from "../../utills/helpers";
import { AuthTokenKey, AuthTokenExpirationKey } from "../../utills/constants";
import { ApiRoutes } from "../../utills/constants/ApiRoutes";

/**
 *
 */
const signupLogic: any = createLogic({
  type: SignupActionTypes.REQUEST_USER_SIGNUP,
  latest: true,
  async process({ action }, dispatch, done) {
    const credentials = (action as any).payload;
    console.log(credentials);
    const { data, isError, messages } = await new ApiHelper().FetchFromServer(
      ApiRoutes.REGISTER.SERVICE,
      ApiRoutes.REGISTER.ENDPOINT,
      ApiRoutes.REGISTER.METHOD,
      ApiRoutes.REGISTER.AUTHENTICATE,
      undefined,
      credentials
    );
    if (isError) {
      dispatch(userSignupFailed());
      dispatch(
        showToastr({
          isOpen: true,
          message: messages[0],
          type: "error",
        })
      );
      done();
      return;
    }

    localStorage.setItem(AuthTokenKey, data.token);
    localStorage.setItem(AuthTokenExpirationKey, data.expires);
    cookie.set("token", data.token, { expires: 1, path: '/', domain: process.env.REACT_APP_DOMAIN_NAME });
    cookie.set("tokenExpiry", data.expires, { expires: 1, path: '/', domain: process.env.REACT_APP_DOMAIN_NAME });
    dispatch(userSignupSuccess());
    dispatch(
      showToastr({
        isOpen: true,
        message: messages[0],
        type: "success",
      })
    );
    dispatch(redirectTo({ path: RouteURLs.DASHBOARD }));
    done();
  },
});

export const SingupLogics: Logic[] = [signupLogic];
