import { IRootState } from "./../../interfaces";
import { Reducer, AnyAction, combineReducers } from "redux";
import { loginReducer } from "./Login";
import { toastrReducer } from "./Toastr";
import { mainLayoutReducer } from "./MainLayout";
import { singupReducer } from "./Signup";
import { postTypesReducer } from "./PostTypes";
import { categoryReducer } from "./Category";
import { postReducer } from "./Post";
import { confirmBoxReducer } from "./ConfirmBox";
import { profileReducer } from "./Profile";
import { paymentMethodReducer } from "./PaymentMethod";
import { modalReducer } from "./Modal";
import { postViewsReducer, earningsReducer, postsAnalyticsReducer, availableBalanceReducer } from "./Dashboard";
import { earningsRecordReducer } from "./Earnings";
import { payoutReducer } from "./CreatePayout";
import { identityVerificationReducer } from "./IdentityVerification";
/**
 *
 */
export const RootReducer: Reducer<IRootState, AnyAction> = combineReducers<
  IRootState
>({
  loginReducer: loginReducer as any,
  toastrReducer: toastrReducer as any,
  mainLayoutReducer: mainLayoutReducer as any,
  singupReducer: singupReducer as any,
  postTypeReducer: postTypesReducer as any,
  categoryReducer: categoryReducer as any,
  postReducer: postReducer as any,
  confirmBoxReducer: confirmBoxReducer as any,
  profileReducer: profileReducer as any,
  paymentMethodsReducer: paymentMethodReducer as any,
  modalReducer: modalReducer as any,
  postViewsReducer: postViewsReducer as any,
  earningsReducer: earningsReducer as any,
  postsAnalyticsReducer: postsAnalyticsReducer as any,
  availableBalanceReducer: availableBalanceReducer as any,
  earningsRecordReducer: earningsRecordReducer as any,
  payoutReducer: payoutReducer as any,
  identityVerificationReducer: identityVerificationReducer as any
});
