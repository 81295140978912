import { IApiRoutes } from "../../interfaces";

export const ApiRoutes: IApiRoutes = {
  LOGIN: {
    SERVICE: "/auth",
    ENDPOINT: "/login",
    AUTHENTICATE: false,
    METHOD: "POST",
  },
  REGISTER: {
    SERVICE: "/auth",
    ENDPOINT: "/register",
    AUTHENTICATE: false,
    METHOD: "POST",
  },
  REFRESH_TOKEN: {
    SERVICE: "/auth",
    ENDPOINT: "/refresh-token",
    AUTHENTICATE: true,
    METHOD: "GET",
  },
  VERIFY_LOGIN_REQUEST: {
    SERVICE: "/auth",
    ENDPOINT: "/login-request-verification",
    AUTHENTICATE: true,
    METHOD: "GET",
  },
  GET_PROFILE_DETAILS: {
    SERVICE: "/auth",
    ENDPOINT: "/me",
    AUTHENTICATE: true,
    METHOD: "GET",
  },
  UPDATE_PROFILE_DETAILS: {
    SERVICE: "/auth",
    ENDPOINT: "/update-profile",
    AUTHENTICATE: true,
    METHOD: "POST",
  },
  GET_POSTS: {
    SERVICE: "/post",
    ENDPOINT: "",
    AUTHENTICATE: true,
    METHOD: "GET",
  },
  ADD_POST: {
    SERVICE: "/post",
    ENDPOINT: "",
    AUTHENTICATE: true,
    METHOD: "POST",
  },
  EDIT_POST: {
    SERVICE: "/post",
    ENDPOINT: "/:id",
    AUTHENTICATE: true,
    METHOD: "PUT",
  },
  DELETE_POST: {
    SERVICE: "/post",
    ENDPOINT: "/:id",
    AUTHENTICATE: true,
    METHOD: "DELETE",
  },
  GET_POST_DETAILS: {
    SERVICE: "/post",
    ENDPOINT: "/details/:id/:slug",
    AUTHENTICATE: true,
    METHOD: "GET",
  },
  CATEGORY_BY_POST_TYPES: {
    SERVICE: "/post-types",
    ENDPOINT: "/category/:id",
    AUTHENTICATE: true,
    METHOD: "GET",
  },
  ADD_PAYOUT_METHOD: {
    SERVICE: "/payout-methods",
    ENDPOINT: "",
    AUTHENTICATE: true,
    METHOD: "POST",
  },
  GET_PAYOUT_METHOD: {
    SERVICE: "/payout-methods",
    ENDPOINT: "",
    AUTHENTICATE: true,
    METHOD: "GET",
  },
  ACTIVATE_ACCOUNT: {
    SERVICE: "/payout-methods",
    ENDPOINT: "/activate/:id",
    AUTHENTICATE: true,
    METHOD: "PATCH",
  },
  DEACTIVATE_ACCOUNT: {
    SERVICE: "/payout-methods",
    ENDPOINT: "/deactivate/:id",
    AUTHENTICATE: true,
    METHOD: "PATCH",
  },
  CREATE_PAYOUT: {
    SERVICE: "/payout",
    ENDPOINT: "",
    AUTHENTICATE: true,
    METHOD: "POST",
  },
  FETCH_PAYOUTS: {
    SERVICE: "/payout",
    ENDPOINT: "",
    AUTHENTICATE: true,
    METHOD: "GET",
  },
  POST_VIEWS_ANALYTICS: {
    SERVICE: "/dashboard",
    ENDPOINT: "/postviews",
    AUTHENTICATE: true,
    METHOD: "GET",
  },
  EARNINGS_ANALYTICS: {
    SERVICE: "/dashboard",
    ENDPOINT: "/earnings",
    AUTHENTICATE: true,
    METHOD: "GET",
  },
  POSTS_ANALYTICS: {
    SERVICE: "/dashboard",
    ENDPOINT: "/posts",
    AUTHENTICATE: true,
    METHOD: "GET",
  },
  AVAILABLE_BALANCE_ANALYTICS: {
    SERVICE: "/dashboard",
    ENDPOINT: "/available-balance",
    AUTHENTICATE: true,
    METHOD: "GET",
  },
  GET_EARNINGS:{
    SERVICE: "/earnings",
    ENDPOINT: "/",
    AUTHENTICATE: true,
    METHOD: "GET",
  },
  GET_IDENTITY_DOCUMENT:{
    SERVICE: "/identity-document",
    ENDPOINT: "/",
    AUTHENTICATE: true,
    METHOD: "GET",
  },
  UPLOAD_IDENTITY_VERIFICATION:{
    SERVICE: "/identity-document",
    ENDPOINT: "/",
    AUTHENTICATE: true,
    METHOD: "POST",
  },
  UPDATE_IDENTITY_VERIFICATION:{
    SERVICE: "/identity-document",
    ENDPOINT: "/",
    AUTHENTICATE: true,
    METHOD: "PUT",
  },
};
