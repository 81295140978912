import { IPostViewsModal, IEarningsModal, IPostsAnalyticsModal, IAvailableBalanceModal } from "../../interfaces";

export const PostViewsInitialState: IPostViewsModal = {
  isLoading: true,
  data: {},
  hasError: false,
};

export const EarningsInitialState: IEarningsModal = {
  isLoading: true,
  data: {},
  hasError: false,
};

export const PostsAnalyticsInitialState: IPostsAnalyticsModal = {
  isLoading: true,
  data: {},
  hasError: false,
};

export const AvailableBalanceInitialState: IAvailableBalanceModal = {
  isLoading: true,
  data: {},
  hasError: false,
};