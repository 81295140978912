import { ProfileInitialState } from "./../states";
import { handleActions } from "redux-actions";
import { IProfileModel } from "../../interfaces";
import { ProfileActions } from "../actions";

export const profileReducer = handleActions<IProfileModel, IProfileModel>(
  {
    [ProfileActions.REQUEST_PROFILE_DETAILS]: (
      state = ProfileInitialState,
      action
    ): IProfileModel => ({
      ...state,
      isLoading: true,
    }),
    [ProfileActions.PROFILE_DETAILS_FAILED]: (
      state = ProfileInitialState,
      action
    ): IProfileModel => ({
      ...state,
      isLoading: false,
    }),
    [ProfileActions.PROFILE_DETAILS_SUCCESS]: (
      state = ProfileInitialState,
      action
    ): IProfileModel => ({
      details: action.payload.details,
      isLoading: false,
      isUpdating: false
    }),
    [ProfileActions.REQUEST_PROFILE_DETAILS]: (
      state = ProfileInitialState,
      action
    ): IProfileModel => ({
      ...state,
      isLoading: true,
    }),
    [ProfileActions.TOGGLE_LOADING]: (
      state = ProfileInitialState,
      action
    ): IProfileModel => ({
      ...state,
      isLoading: action.payload.isLoading,
    }),
    [ProfileActions.TOGGLE_UPDATE_LOADING]: (
      state = ProfileInitialState,
      action
    ): IProfileModel => ({
      ...state,
      isUpdating: action.payload.isUpdating,
    }),
  },
  ProfileInitialState
);
