import { createAction } from "redux-actions";

export enum EarningsActions {
  GET_EARNINGS = "Request Earnings!",
  FETCH_EARNINGS_SUCCESS = "Fetch Earnings success!",
  FETCH_EARNINGS_FAILED = "Fetch Earnings failed!",
  TOGGLE_LOADER = "Toggle earnings loader",
}

export const requestEarningsRecords = createAction(
  EarningsActions.GET_EARNINGS
);
export const earningsRecordsSuccess = createAction(
  EarningsActions.FETCH_EARNINGS_SUCCESS
);
export const earningsRecordsFailed = createAction(
  EarningsActions.FETCH_EARNINGS_FAILED
);
export const toggleEarningsRecordsLoader = createAction(
  EarningsActions.TOGGLE_LOADER
);