import { CategoryInitialState } from "./../states";
import { handleActions } from "redux-actions";
import { ICategoryModel } from "../../interfaces";
import { CategoryActions } from "../actions";

export const categoryReducer = handleActions<ICategoryModel, ICategoryModel>(
  {
    [CategoryActions.GET_CATEGORIES]: (
      state = CategoryInitialState,
      action
    ): ICategoryModel => ({
      isLoading: true,
      data: [],
    }),
    [CategoryActions.CATEGORIES_LIST_FAILED]: (
      state = CategoryInitialState,
      action
    ): ICategoryModel => ({
      isLoading: false,
      data: [],
    }),
    [CategoryActions.CATEGORY_LIST_SUCCESS]: (
      state = CategoryInitialState,
      action
    ): ICategoryModel => ({
      isLoading: false,
      data: action.payload.data,
    }),
  },
  CategoryInitialState
);
