import { createLogic, Logic } from "redux-logic";
import { IdentityVerificationActions, toggleIdentityVerificationLoader, identityVerificationFailed, identityVerificationSuccess, toggleIdentityDocumentLoader, identityDocumentSuccess, showToastr } from "../actions";
import { ApiRoutes } from "../../utills/constants/ApiRoutes";
import { ApiHelper } from "../../utills/helpers";
import { IEndpoint } from "../../interfaces";

/**
 *
 */
const requestIdentityVerification = createLogic({
    type: IdentityVerificationActions.REQUEST_IDENTITY_VERIFICATION,
    latest: true,
    process: async ({ action }, dispatch, done) => {
        dispatch(toggleIdentityVerificationLoader({ isAdding: true }));
        const payload = (action as any).payload;
        const formData = new FormData();
        for (const key in payload) {
            if (payload.hasOwnProperty(key)) {
                const element = payload[key];
                formData.append(key, element);
            }
        }
        const apiEndpoint: IEndpoint = payload.action === "update" ? ApiRoutes.UPDATE_IDENTITY_VERIFICATION : ApiRoutes.UPLOAD_IDENTITY_VERIFICATION;
        const { data, isError, messages } = await new ApiHelper().FetchFromServer(
            apiEndpoint.SERVICE,
            apiEndpoint.ENDPOINT,
            apiEndpoint.METHOD,
            apiEndpoint.AUTHENTICATE,
            undefined,
            formData
        );
        if (isError) {
            dispatch(identityVerificationFailed());
            dispatch(
                showToastr({
                    isOpen: true,
                    message: messages[0],
                    type: "error",
                })
            );
            dispatch(toggleIdentityVerificationLoader({ isAdding: false }));
            done();
            return;
        }
        dispatch(
            identityVerificationSuccess({
                data: data,
            })
        );
        dispatch(
            showToastr({
                isOpen: true,
                message: messages[0],
                type: "success",
            })
        );
        dispatch(toggleIdentityVerificationLoader({ isAdding: false }));
        done();
    },
});

/**
*
*/
const getIdentityDocument = createLogic({
    type: IdentityVerificationActions.GET_IDENTITY_DOCUMENT,
    latest: true,
    process: async ({ action }, dispatch, done) => {
        dispatch(toggleIdentityDocumentLoader({ isLoading: true }));
        const payload = (action as any).payload;
        const formData = new FormData();
        for (const key in payload) {
            if (payload.hasOwnProperty(key)) {
                const element = payload[key];
                formData.append(key, element);
            }
        }
        const { data, isError } = await new ApiHelper().FetchFromServer(
            ApiRoutes.GET_IDENTITY_DOCUMENT.SERVICE,
            ApiRoutes.GET_IDENTITY_DOCUMENT.ENDPOINT,
            ApiRoutes.GET_IDENTITY_DOCUMENT.METHOD,
            ApiRoutes.GET_IDENTITY_DOCUMENT.AUTHENTICATE,
            undefined,
            undefined
        );
        if (isError) {
            dispatch(identityVerificationFailed());
            dispatch(toggleIdentityDocumentLoader({ isLoading: false }));
            done();
            return;
        }
        dispatch(
            identityDocumentSuccess({
                data: data.data || {},
            })
        );
        dispatch(toggleIdentityDocumentLoader({ isLoading: false }));
        done();
    },
});

export const IdentityVerificationLogics: Logic[] = [requestIdentityVerification as Logic, getIdentityDocument as Logic];