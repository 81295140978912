import React from "react";
import {
  DashboardRounded,
  PostAddOutlined,
  AccountCircleOutlined,
} from "@material-ui/icons";
import LogoutIcon from "@material-ui/icons/SettingsPowerRounded";
import MoneyIcon from '@material-ui/icons/Money';
import { IRoutes, ISidebarOptions, IRouteURLs } from "../interfaces/Routes";

const LoginPage = React.lazy(() => import("../resources/pages/Login"));
const SignupPage = React.lazy(() => import("../resources/pages/Signup"));
const VerifyLoginReq = React.lazy(() => import("../resources/pages/Login/VerifyLoginReq"));

const HomePage = React.lazy(() => import("../resources/pages/Home"));
const PostPage = React.lazy(() => import("../resources/pages/Posts"));
const AddPostPage = React.lazy(() => import("../resources/pages/AddPost"));
const Earnings = React.lazy(() => import("../resources/pages/Earnings"));
const Transactions = React.lazy(() => import("../resources/pages/Transactions"));
const MyProfilePage = React.lazy(() => import("../resources/pages/MyProfile"));
const LogoutPage = React.lazy(() => import("../resources/pages/Logout"));
/**
 *
 */
export const RouteURLs: IRouteURLs = {
  LOGIN: "/sign-in",
  SIGN_UP: "/sign-up",
  FORGOT_PASSWORD: "/forgot-password",
  VERIFY_LOGIN_REQUEST: "/verify-login-req",
  RESET_PASSWORD: "/reset-password",
  DASHBOARD: "/",
  POSTS: "/posts",
  ADD_POST: "/post/add",
  EDIT_POST: "/post/edit/:id/:slug",
  EARNINGS:'/earnings',
  PAYOUTS:'/payout-history',
  MY_PROFILE: "/profile",
  LOGOUT: "/logout",
};
/**
 *
 */
export const MainRoutes: IRoutes[] = [
  {
    path: RouteURLs.DASHBOARD,
    exact: true,
    component: HomePage,
    name: "Dashboard",
  },
  {
    path: RouteURLs.POSTS,
    exact: true,
    component: PostPage,
    name: "All Post",
  },
  {
    path: RouteURLs.ADD_POST,
    exact: true,
    component: AddPostPage,
    name: "Add New Post",
  },
  {
    path: RouteURLs.EDIT_POST,
    exact: true,
    component: AddPostPage,
    name: "Edit Post",
  },
  {
    path: RouteURLs.EARNINGS,
    exact: true,
    component: Earnings,
    name: "Earnings",
  },
  {
    path: RouteURLs.PAYOUTS,
    exact: true,
    component: Transactions,
    name: "Payout History",
  },
  {
    path: RouteURLs.MY_PROFILE,
    exact: true,
    component: MyProfilePage,
    name: "My Profile",
  },
  {
    path: RouteURLs.LOGOUT,
    exact: true,
    component: LogoutPage,
    name: "Logout",
  },
];
/**
 *
 */
export const AuthRoutes: IRoutes[] = [
  {
    path: RouteURLs.LOGIN,
    exact: true,
    component: LoginPage,
  },
  {
    path: RouteURLs.SIGN_UP,
    exact: true,
    component: SignupPage,
  },
  {
    path: RouteURLs.FORGOT_PASSWORD,
    exact: true,
    component: LoginPage,
  },
  {
    path: RouteURLs.VERIFY_LOGIN_REQUEST,
    exact: true,
    component: VerifyLoginReq,
  },
];
/**
 *
 */
export const SidebarOptions: ISidebarOptions[] = [
  {
    route: RouteURLs.DASHBOARD,
    name: "Dashboard",
    icon: DashboardRounded,
  },
  {
    route: RouteURLs.POSTS,
    name: "Posts",
    icon: PostAddOutlined,
  },
  {
    route: RouteURLs.EARNINGS,
    name: "Earnings",
    icon: MoneyIcon,
  },
  {
    route: RouteURLs.PAYOUTS,
    name: "Payout History",
    icon: MoneyIcon,
  },
  {
    route: RouteURLs.MY_PROFILE,
    name: "Profile",
    icon: AccountCircleOutlined,
  },
  {
    route: RouteURLs.LOGOUT,
    name: "Logout",
    icon: LogoutIcon,
  },
];
