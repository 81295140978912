import { createAction } from "redux-actions";

export enum CategoryActions {
  GET_CATEGORIES = "Request Category list by post type!",
  CATEGORIES_LIST_FAILED = "Category list failed!",
  CATEGORY_LIST_SUCCESS = "Category list success!",
}

export const requestCategoryList = createAction(CategoryActions.GET_CATEGORIES);

export const categoryListFailed = createAction(
  CategoryActions.CATEGORIES_LIST_FAILED
);

export const categoryListSuccess = createAction(
  CategoryActions.CATEGORY_LIST_SUCCESS
);
