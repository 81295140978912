export const PostsPerPage = 12;
export const PostsPerPageOptions = [
  PostsPerPage,
  PostsPerPage * 2,
  PostsPerPage * 4,
  PostsPerPage * 6,
  PostsPerPage * 8,
  PostsPerPage * 10,
];
export const EarningsPerPage = 10;
export const EarningsPerPageOptions = [
  EarningsPerPage,
  EarningsPerPage * 2,
  EarningsPerPage * 4,
  EarningsPerPage * 6,
  EarningsPerPage * 8,
  EarningsPerPage * 10,
];
export const AuthTokenKey = "token";
export const AuthTokenExpirationKey = "tokenExpiry";
