import { IProfileModel } from "../../interfaces";

export const ProfileInitialState: IProfileModel = {
  isLoading: true,
  isUpdating: false,
  details: {
    firstName: "",
    lastName: "",
    username: "",
    email: "",
  },
};
