import { createAction } from "redux-actions";
import { IConfirmBoxModel } from "../../interfaces";

export enum ConfirmBoxActions {
  TOGGLE_CONFIRM_BOX = "Toggle confirmation box!",
}

export const toggleConfirmBox = createAction<IConfirmBoxModel>(
  ConfirmBoxActions.TOGGLE_CONFIRM_BOX
);
