import { PostInitialState } from "./../states";
import { handleActions } from "redux-actions";
import { IPostModel } from "../../interfaces";
import { PostActions } from "../actions";

export const postReducer = handleActions<IPostModel, IPostModel>(
  {
    [PostActions.REQUEST_ADD_POST]: (
      state = PostInitialState,
      action
    ): IPostModel => ({
      ...state,
      isPostAdding: true,
    }),
    [PostActions.ADD_POST_FAILED]: (
      state = PostInitialState,
      action
    ): IPostModel => ({
      ...state,
      isPostAdding: false,
    }),
    [PostActions.ADD_POST_SUCCESS]: (
      state = PostInitialState,
      action
    ): IPostModel => ({
      ...state,
      isPostAdding: false,
    }),
    [PostActions.REQUEST_GET_POST]: (
      state = PostInitialState,
      action
    ): IPostModel => ({
      ...state,
      isLoading: true,
    }),
    [PostActions.GET_POST_FAILED]: (
      state = PostInitialState,
      action
    ): IPostModel => ({
      ...state,
      isLoading: false,
      data: [],
    }),
    [PostActions.GET_POST_SUCCESS]: (
      state = PostInitialState,
      action
    ): IPostModel => ({
      ...state,
      isLoading: false,
      totalRecords: action.payload.totalRecords,
      data: action.payload.data,
    }),
    [PostActions.REQUEST_POST_DETAILS]: (
      state = PostInitialState,
      action
    ): IPostModel => ({
      ...state,
      isDetailsLoading: true,
      editPostDetails: PostInitialState.editPostDetails,
    }),
    [PostActions.POST_DETAILS_SUCCESS]: (
      state = PostInitialState,
      action
    ): IPostModel => ({
      ...state,
      isDetailsLoading: false,
      editPostDetails: action.payload.editPostDetails,
    }),
    [PostActions.POST_DETAILS_FAILED]: (
      state = PostInitialState,
      action
    ): IPostModel => ({
      ...state,
      isDetailsLoading: false,
      editPostDetails: PostInitialState.editPostDetails,
    }),
  },
  PostInitialState
);
