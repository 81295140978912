import { handleActions } from "redux-actions";
import { IIdentityVerificationModel } from "../../interfaces";
import { IdentityVerificationActions } from "../actions";
import { IdentityVerificationInitialState } from "./../states";

export const identityVerificationReducer = handleActions<
  IIdentityVerificationModel,
  IIdentityVerificationModel
>(
  {
    [IdentityVerificationActions.TOGGLE_SAVE_LOADING]: (
      state = IdentityVerificationInitialState,
      action
    ): IIdentityVerificationModel => ({
      ...state,
      isAdding: action.payload.isAdding,
    }),
    [IdentityVerificationActions.TOGGLE_LOADING]: (
      state = IdentityVerificationInitialState,
      action
    ): IIdentityVerificationModel => ({
      ...state,
      isLoading: action.payload.isLoading,
    }),
    // [IdentityVerificationActions.PAYOUT_METHOD_LIST_FAILED]: (
    //   state = IdentityVerificationInitialState,
    //   action
    // ): IIdentityVerificationModel => ({
    //   ...state,
    //   isLoading: false,
    //   list: [],
    // }),

    [IdentityVerificationActions.IDENTITY_DOCUMENT_FETCHED_SUCCESS]: (
      state = IdentityVerificationInitialState,
      action
    ): IIdentityVerificationModel => ({
      ...state,
      isLoading: false,
      data: action.payload.data,
    }),
  },
  IdentityVerificationInitialState
);
