import { handleActions } from "redux-actions";
import { IPostViewsModal, IEarningsModal, IPostsAnalyticsModal, IAvailableBalanceModal } from "../../interfaces";
import { DashboardActions } from "../actions";
import { PostViewsInitialState, EarningsInitialState, PostsAnalyticsInitialState, AvailableBalanceInitialState } from "./../states";

export const postViewsReducer = handleActions<IPostViewsModal, IPostViewsModal>(
  {
    [DashboardActions.TOGGLE_LOADER]: (
      state = PostViewsInitialState,
      action
    ): IPostViewsModal => ({
      ...state,
      isLoading: action.payload.isLoading,
    }),
    [DashboardActions.TOTAL_POST_VIEWS_SUCCESS]: (
      state = PostViewsInitialState,
      action
    ): IPostViewsModal => ({
      isLoading: false,
      hasError: false,
      data: action.payload.data,
    }),
    [DashboardActions.TOTAL_POST_VIEWS_FAILED]: (
      state = PostViewsInitialState,
      action
    ): IPostViewsModal => ({
      isLoading: false,
      hasError: true,
      data: {},
    }),
  },
  PostViewsInitialState
);

export const earningsReducer = handleActions<IEarningsModal, IEarningsModal>(
  {
    [DashboardActions.TOGGLE_EARNINGS_LOADER]: (
      state = EarningsInitialState,
      action
    ): IEarningsModal => ({
      ...state,
      isLoading: action.payload.isLoading,
    }),
    [DashboardActions.TOTAL_EARNINGS_SUCCESS]: (
      state = EarningsInitialState,
      action
    ): IEarningsModal => ({
      isLoading: false,
      hasError: false,
      data: action.payload.data,
    }),
    [DashboardActions.TOTAL_EARNINGS_FAILED]: (
      state = EarningsInitialState,
      action
    ): IEarningsModal => ({
      isLoading: false,
      hasError: true,
      data: {},
    }),
  },
  EarningsInitialState
);

export const postsAnalyticsReducer = handleActions<IPostsAnalyticsModal, IPostsAnalyticsModal>(
  {
    [DashboardActions.TOGGLE_POST_ANALYTICS_LOADER]: (
      state = PostsAnalyticsInitialState,
      action
    ): IPostsAnalyticsModal => ({
      ...state,
      isLoading: action.payload.isLoading,
    }),
    [DashboardActions.TOTAL_POST_SUCCESS]: (
      state = PostsAnalyticsInitialState,
      action
    ): IPostsAnalyticsModal => ({
      isLoading: false,
      hasError: false,
      data: action.payload.data,
    }),
    [DashboardActions.TOTAL_POST_FAILED]: (
      state = PostsAnalyticsInitialState,
      action
    ): IPostsAnalyticsModal => ({
      isLoading: false,
      hasError: true,
      data: {},
    }),
  },
  PostsAnalyticsInitialState
);

export const availableBalanceReducer = handleActions<IAvailableBalanceModal, IAvailableBalanceModal>(
  {
    [DashboardActions.TOGGLE_POST_ANALYTICS_LOADER]: (
      state = AvailableBalanceInitialState,
      action
    ): IAvailableBalanceModal => ({
      ...state,
      isLoading: action.payload.isLoading,
    }),
    [DashboardActions.TOTAL_AVAILABLE_BALANCE_SUCCESS]: (
      state = AvailableBalanceInitialState,
      action
    ): IAvailableBalanceModal => ({
      isLoading: false,
      hasError: false,
      data: action.payload.data,
    }),
    [DashboardActions.TOTAL_AVAILABLE_BALANCE_FAILED]: (
      state = AvailableBalanceInitialState,
      action
    ): IAvailableBalanceModal => ({
      isLoading: false,
      hasError: true,
      data: {},
    }),
  },
  PostsAnalyticsInitialState
);