import { createLogic, Logic } from "redux-logic";
import {
  PostTypeActions,
  showToastr,
  postTypeFailed,
  postTypeSuccess,
} from "../actions";
import { ApiHelper } from "../../utills/helpers";

const getPostType: any = createLogic({
  type: PostTypeActions.GET_POST_TYPES,
  latest: true,
  async process({ action }, dispatch, done) {
    const { data, isError, messages } = await new ApiHelper().FetchFromServer(
      "/post-types",
      "",
      "GET",
      true
    );
    if (isError) {
      dispatch(postTypeFailed());
      dispatch(
        showToastr({
          isOpen: true,
          message: messages[0],
          type: "error",
        })
      );
      done();
      return;
    }
    dispatch(
      postTypeSuccess({
        data,
      })
    );
    done();
  },
});

export const PostTypesLogics: Logic[] = [getPostType];
