import { createAction } from "redux-actions";

export enum LoginActionTypes {
  REQUEST_USER_LOGIN = "Request login user!",
  USER_LOGIN_FAILED = "User login failed!",
  USER_LOGIN_SUCCESS = "User login success!",
  REQUIRE_RELOGIN = "Need to relogin",
  REFRESH_TOKEN = "Refresh login token!",
  REQUEST_LOGIN_VERIFY = "Request verification for login to user portal!",
}

export const requestLogin = createAction(LoginActionTypes.REQUEST_USER_LOGIN);

export const userLoginFailed = createAction(LoginActionTypes.USER_LOGIN_FAILED);

export const userLoginSuccess = createAction(
  LoginActionTypes.USER_LOGIN_SUCCESS
);

export const requestRelogin = createAction(LoginActionTypes.REQUIRE_RELOGIN);
export const refreshLoginToken = createAction(LoginActionTypes.REFRESH_TOKEN);
export const reqLoginVerification = createAction(LoginActionTypes.REQUEST_LOGIN_VERIFY);
