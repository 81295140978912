import Axios, { AxiosRequestConfig, AxiosResponse, Method } from "axios";
import cookie from "js-cookie";
import { ErrorHandlerHelper } from "./ErrorHandlerHelper";
import { SuccessHandlerHelper } from "./SuccessHandlerHelper";
import { requestRelogin } from "../../redux/actions";
import store from "../../redux";

/**
 * ApiHelper Class - For making Api Requests
 */
let CancelToken = Axios.CancelToken;
let cancel: any;
export class ApiHelper {
  _portalGateway: string;
  _apiVersion: string;
  source: any;
  cancelToken: any;
  constructor() {
    this._portalGateway = process.env.REACT_APP_API_ENDPOINT || "";
    this._apiVersion = process.env.REACT_APP_API_VERSION || "";
    this.source = Axios.CancelToken.source();
    this.cancelToken = this.source.token;
    return this;
  }
  setHost = (host: string) => {
    this._portalGateway = host;
  };
  setApiVersion = (version: string) => {
    this._apiVersion = version;
  };
  /**
   * Fetches from the Gateway defined by the instantiated object. Accepts <T> as output object.
   * @example <caption>"/Auth/UserAccount", "/GetCurrentUser", "GET", "JWT Content"</caption>
   * @param {service} service - wanting to be access ex. "UserAuth/Auth"
   * @param {endpoint} endpoint - you wish to call ex. "/Login"
   * @param {method} mehotd - method (GET, UPDATE, DELETE, POST)
   * @param {jwt} JWT - JSON Web Token (Optional)
   * @param {queryOptions} Query - query options for "GET" methods (Optional)
   * @param {body} body - JSON body for "UPDATE, DELETE and POST" methods (Optional)
   */
  async FetchFromServer(
    service: string,
    endpoint: string,
    method: Method,
    authenticated: boolean = false,
    queryOptions?: any,
    body?: any,
    options?: AxiosRequestConfig,
    responseType?: any
  ) {
    if (!options) {
      options = {};
    }
    options.method = method;
    if (!options.headers) {
      options.headers = { "Content-Type": "application/json" };
    }
    let url: string = this._apiVersion + service + endpoint;
    if (responseType === "blob") {
      options.responseType = "blob";
    }
    if (authenticated) {
      const storageSession = cookie.get('token');
      // localStorage.getItem("token");
      options.headers.Authorization = storageSession;
    }
    options.headers.portal = true;
    // html query for "GET", json body for others.
    if (queryOptions && typeof queryOptions === "object") {
      let queryParams = [] as string[];
      Object.keys(queryOptions).map((key) => {
        queryParams.push(`${key}=${(queryOptions as any)[key]}`);
        return key;
      });
      url += `?${queryParams.join("&")}`;
    }

    if (body) {
      options.data = body;
    }
    try {
      let response: AxiosResponse<any> = await Axios({
        ...options,
        url: `${this._portalGateway}${url}`,
        cancelToken: new CancelToken(function executor(c: any) {
          // An executor function receives a cancel function as a parameter
          cancel = c;
        }),
      });

      if (response.status < 200 || response.status >= 300) {
        let errorObject: any = {
          code: response.status,
          response: response.data,
        };

        throw errorObject;
      }
      const data: SuccessHandlerHelper = new SuccessHandlerHelper(
        response.data
      );
      return data.data;
    } catch (err) {
      if (Axios.isCancel(err) || !err.response) {
        return {
          data: [],
          isError: true,
          error: "Request cancelled",
          messages: err.message === "cancel" ? [] : ["Request cancelled"],
        };
      }
      const errorHelper: ErrorHandlerHelper = new ErrorHandlerHelper(
        err.response
      );
      console.log(errorHelper);
      if (errorHelper.error.code === 401) {
        store.dispatch(requestRelogin());
      }
      return errorHelper.error;
    }
  }

  // Function to upload file in form data
  UploadImage = async (
    service: string,
    endpoint: string,
    body: any,
    jsonData: string[] = []
  ) => {
    let fd = new FormData();

    for (const k in body) {
      if (body.hasOwnProperty(k)) {
        const element = body[k];
        if (
          k === "characteristic" ||
          k === "audio" ||
          k === "video" ||
          k === "removedAttachments" ||
          k === "courseId" ||
          k === "moduleId" ||
          k === "organizationId" ||
          k === "text" ||
          jsonData.indexOf(k) > -1
        ) {
          fd.append(k, JSON.stringify(element));
        } else {
          fd.append(k, element);
        }
      }
    }
    let url: string = this._apiVersion + service + endpoint;
    let options: AxiosRequestConfig = { method: "POST" };
    options.headers = {};
    const storageSession = cookie.get('token');
    // localStorage.getItem("token");
    options.headers.Authorization = storageSession;

    try {
      let response: AxiosResponse<any> = await Axios.post(
        `${this._portalGateway}${url}`,
        fd,
        {
          headers: options.headers,
        }
      );

      if (response.status < 200 || response.status >= 300) {
        let errorObject: any = {
          code: response.status,
          response: response.data,
        };

        throw errorObject;
      }
      const data: SuccessHandlerHelper = new SuccessHandlerHelper(
        response.data
      );
      return data.data;
    } catch (err) {
      if (Axios.isCancel(err) || !err.response) {
        return {
          data: [],
          isError: true,
          error: "Request cancelled",
          messages: err.message === "cancel" ? [] : ["Request cancelled"],
        };
      }
      const errorHelper: ErrorHandlerHelper = new ErrorHandlerHelper(
        err.response
      );
      return errorHelper.error;
    }
  };
  /**
   * Cancels the last request.
   */
  cancelRequest = (err: any) => {
    cancel && cancel(err);
  };
}
