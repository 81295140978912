import { createAction } from "redux-actions";

export enum PostTypeActions {
  GET_POST_TYPES = "Request post types!",
  POST_TYPES_FAILED = "Post type list failed!",
  POST_TYPES_SUCCESS = "Post type list success!",
}

export const requestPostTypes = createAction(PostTypeActions.GET_POST_TYPES);

export const postTypeFailed = createAction(PostTypeActions.POST_TYPES_FAILED);

export const postTypeSuccess = createAction(PostTypeActions.POST_TYPES_SUCCESS);
