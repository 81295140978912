import React from "react";
import { Switch, Route } from "react-router-dom";
import { AuthRoutes } from "./routes";
import { Snackbar, Button } from "@material-ui/core";
import MuiAlert from "@material-ui/lab/Alert";
import { IRootState } from "../interfaces";
import { connect } from "react-redux";
import { hideToastr } from "../redux/actions";
import { Dispatch } from "redux";
import Loader from "../resources/components/Common/Loader";
import ConfirmBox from "../resources/components/Common/ConfirmBox";

const MainLayout = React.lazy(() => import("./../resources/layout/Main"));
const AuthLayout = React.lazy(() => import("./../resources/layout/Auth"));

const Routes = ({ toastrState, closeToastr, confirmBoxState }: any) => {
  const { isOpen, message, showUndo, unActionCallback, type } = toastrState;
  return (
    <React.Suspense fallback={<Loader />}>
      <Switch>
        {AuthRoutes.map(({ path, exact, component }, index) => (
          <Route
            key={`${index}-${path}`}
            path={path}
            exact={exact}
            render={() => <AuthLayout component={component} />}
          />
        ))}
        <Route key={"main-path"} path={"/"} component={MainLayout} />
      </Switch>
      <Snackbar
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        open={
          isOpen && ["error", "warning", "info", "success"].indexOf(type) > -1
        }
        autoHideDuration={5000}
        onClose={closeToastr}
      >
        <MuiAlert
          elevation={6}
          variant="filled"
          severity={type}
          onClose={closeToastr}
        >
          {message}{" "}
          {showUndo ? (
            <Button
              color="secondary"
              size="small"
              onClick={unActionCallback || closeToastr}
            >
              UNDO
            </Button>
          ) : null}
        </MuiAlert>
      </Snackbar>
      <ConfirmBox {...confirmBoxState} />
    </React.Suspense>
  );
};
const mapStateToProps = (state: IRootState) => ({
  toastrState: state.toastrReducer,
  confirmBoxState: state.confirmBoxReducer,
});
const mapDispatchToProps = (dispatch: Dispatch) => ({
  closeToastr: () => dispatch(hideToastr()),
});
export default connect(mapStateToProps, mapDispatchToProps)(Routes);
