import { Logic, createLogic } from "redux-logic";
import {
  PayoutMethodsActions,
  addPayoutMethodFailed,
  showToastr,
  addPayoutMethodSuccess,
  toggleModals,
  toggleAddPaymentMethodLoader,
  payoutMethodSuccess,
  togglePaymentMethodLoader,
  payoutMethodFailed,
  requestPayoutMethods,
} from "./../actions";
import { ApiHelper } from "../../utills/helpers";
import { ApiRoutes } from "../../utills/constants/ApiRoutes";
import { IRootState } from "../../interfaces";
/**
 *
 */
const addPaymentMethodLogic = createLogic({
  type: PayoutMethodsActions.REQUEST_ADD_PAYOUT_METHOD,
  process: async ({ action }, dispatch, done) => {
    dispatch(
      toggleAddPaymentMethodLoader({
        isAdding: true,
      })
    );
    const { isError, messages } = await new ApiHelper().FetchFromServer(
      ApiRoutes.ADD_PAYOUT_METHOD.SERVICE,
      ApiRoutes.ADD_PAYOUT_METHOD.ENDPOINT,
      ApiRoutes.ADD_PAYOUT_METHOD.METHOD,
      ApiRoutes.ADD_PAYOUT_METHOD.AUTHENTICATE,
      undefined,
      (action as any).payload
    );
    if (isError) {
      dispatch(addPayoutMethodFailed({}));
      dispatch(
        showToastr({
          isOpen: true,
          message: messages[0],
          type: "error",
        })
      );
      dispatch(
        toggleAddPaymentMethodLoader({
          isAdding: false,
        })
      );
      done();
      return;
    }
    dispatch(addPayoutMethodSuccess({}));
    dispatch(
      showToastr({
        isOpen: true,
        message: "Payout method added successfully!",
        type: "success",
      })
    );
    dispatch(
      toggleModals({
        showAddPaymentMethodModal: false,
      })
    );
    dispatch(
      toggleAddPaymentMethodLoader({
        isAdding: false,
      })
    );
    dispatch(requestPayoutMethods());
    done();
  },
});
/**
 *
 */
const getPayoutMethods = createLogic({
  type: PayoutMethodsActions.GET_PAYOUT_METHOD_LISTS,
  process: async (_, dispatch, done) => {
    dispatch(
      togglePaymentMethodLoader({
        isLoading: true,
      })
    );
    const { isError, data } = await new ApiHelper().FetchFromServer(
      ApiRoutes.GET_PAYOUT_METHOD.SERVICE,
      ApiRoutes.GET_PAYOUT_METHOD.ENDPOINT,
      ApiRoutes.GET_PAYOUT_METHOD.METHOD,
      ApiRoutes.GET_PAYOUT_METHOD.AUTHENTICATE
    );
    if (isError) {
      dispatch(payoutMethodFailed());
      done();
      return;
    }
    dispatch(
      payoutMethodSuccess({
        list: data.items,
      })
    );
    done();
  },
});
/**
 *
 */
const activateAccount = createLogic({
  type: PayoutMethodsActions.ACTIVATE_ACCOUNT,
  process: async ({ action, getState }, dispatch, done) => {
    const paymentMethods = (getState() as IRootState).paymentMethodsReducer
      .list;
    const { id } = (action as any).payload;
    const { isError, messages } = await new ApiHelper().FetchFromServer(
      ApiRoutes.ACTIVATE_ACCOUNT.SERVICE,
      ApiRoutes.ACTIVATE_ACCOUNT.ENDPOINT.replace(":id", id),
      ApiRoutes.ACTIVATE_ACCOUNT.METHOD,
      ApiRoutes.ACTIVATE_ACCOUNT.AUTHENTICATE
    );
    if (isError) {
      dispatch(
        showToastr({
          isOpen: true,
          message: messages[0],
          type: "error",
        })
      );
      done();
      return;
    }
    const index = paymentMethods.findIndex((payment: any) => payment.id === id);
    paymentMethods[index].active = true;
    dispatch(
      payoutMethodSuccess({
        list: paymentMethods,
      })
    );
    done();
  },
});
/**
 *
 */
const deactivateAccount = createLogic({
  type: PayoutMethodsActions.DEACTIVATE_ACCOUNT,
  process: async ({ action, getState }, dispatch, done) => {
    const paymentMethods = (getState() as IRootState).paymentMethodsReducer
      .list;
    const { id } = (action as any).payload;
    const { isError, messages } = await new ApiHelper().FetchFromServer(
      ApiRoutes.DEACTIVATE_ACCOUNT.SERVICE,
      ApiRoutes.DEACTIVATE_ACCOUNT.ENDPOINT.replace(":id", id),
      ApiRoutes.DEACTIVATE_ACCOUNT.METHOD,
      ApiRoutes.DEACTIVATE_ACCOUNT.AUTHENTICATE
    );
    if (isError) {
      dispatch(
        showToastr({
          isOpen: true,
          message: messages[0],
          type: "error",
        })
      );
      done();
      return;
    }
    const index = paymentMethods.findIndex((payment: any) => payment.id === id);
    paymentMethods[index].active = false;
    dispatch(
      payoutMethodSuccess({
        list: paymentMethods,
      })
    );
    done();
  },
});
/**
 *
 */
export const PaymentMethodLogics: Logic[] = [
  addPaymentMethodLogic as Logic,
  getPayoutMethods as Logic,
  activateAccount as Logic,
  deactivateAccount as Logic,
];
