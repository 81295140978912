import { createAction } from "redux-actions";

export enum IdentityVerificationActions {
  REQUEST_IDENTITY_VERIFICATION = "Request identity verification!",
  IDENTITY_VERIFICATION_FAILED = "Identity verification failed!",
  IDENTITY_VERIFICATION_SUCCESS = "Identity verification success!",
  GET_IDENTITY_DOCUMENT = "Get Identity document",
  IDENTITY_DOCUMENT_FETCHED_SUCCESS = "Identity document fetched successfully",
  IDENTITY_DOCUMENT_FETCHED_FAILED = "Identity document failed to fetch",
  TOGGLE_LOADING = "Toggle identity document loader!",
  TOGGLE_SAVE_LOADING = "Toggle identity verification loader!",
}

export const requestIdentityVerfication = createAction(
  IdentityVerificationActions.REQUEST_IDENTITY_VERIFICATION
);

export const identityVerificationFailed = createAction(
  IdentityVerificationActions.IDENTITY_VERIFICATION_FAILED
);

export const identityVerificationSuccess = createAction(
  IdentityVerificationActions.IDENTITY_VERIFICATION_SUCCESS
);

export const getIdentityDocument = createAction(
    IdentityVerificationActions.GET_IDENTITY_DOCUMENT
  );
  
  export const identityDocumentSuccess = createAction(
    IdentityVerificationActions.IDENTITY_DOCUMENT_FETCHED_SUCCESS
  );
  
  export const identityDocumentFailed = createAction(
    IdentityVerificationActions.IDENTITY_VERIFICATION_SUCCESS
  );

export const toggleIdentityDocumentLoader = createAction(IdentityVerificationActions.TOGGLE_LOADING);

export const toggleIdentityVerificationLoader = createAction(IdentityVerificationActions.TOGGLE_SAVE_LOADING);
