import { LoginInitialState } from "./../states";
import { handleActions } from "redux-actions";
import { ILoginModal } from "../../interfaces";
import { LoginActionTypes } from "../actions";

export const loginReducer = handleActions<ILoginModal, ILoginModal>(
  {
    [LoginActionTypes.REQUEST_USER_LOGIN]: (
      state = LoginInitialState,
      action
    ): ILoginModal => ({
      ...state,
      isLoading: true,
    }),
    [LoginActionTypes.USER_LOGIN_FAILED]: (
      state = LoginInitialState,
      action
    ): ILoginModal => ({
      ...state,
      isAuthenticated: false,
      isLoading: false,
    }),
    [LoginActionTypes.USER_LOGIN_SUCCESS]: (
      state = LoginInitialState,
      action
    ): ILoginModal => ({
      ...state,
      isAuthenticated: true,
      isLoading: false,
      reLoginRequired: false,
    }),
    [LoginActionTypes.REQUIRE_RELOGIN]: (
      state = LoginInitialState,
      action
    ): ILoginModal => ({
      ...state,
      reLoginRequired: true,
    }),
  },
  LoginInitialState
);
