import { handleActions } from "redux-actions";
import { IPayoutModel } from "../../interfaces";
import { PayoutActions } from "../actions";
import { PayoutInitialState } from "../states";

export const payoutReducer = handleActions<
    any,
    any
>(
    {
        [PayoutActions.REQUEST_CREATE_PAYOUT]: (
            state = { isLoading: false },
            action
        ): IPayoutModel => ({
            ...state,
            isLoading: true,
        }),
        [PayoutActions.PAYOUT_SUCCESS]: (
            state = { isLoading: false },
            action
        ): IPayoutModel => ({
            ...state,
            isLoading: false,
        }),
        [PayoutActions.PAYOUT_FAILED]: (
            state = { isLoading: false },
            action
        ): IPayoutModel => ({
            ...state,
            isLoading: false,
        }),
        [PayoutActions.REQUEST_PAYOUT_LIST]: (
            state = { isLoading: false },
            action
        ): IPayoutModel => ({
            ...state,
            isLoading: true,
        }),
        [PayoutActions.PAYOUT_LIST_SUCCESS]: (
            state = { isLoading: false },
            action
        ): IPayoutModel => ({
            ...state,
            isLoading: false,
            data: action.payload.data,
            totalRecords: action.payload.totalRecords
        }),
        [PayoutActions.PAYOUT_LIST_FAILED]: (
            state = { isLoading: false },
            action
        ): IPayoutModel => ({
            ...state,
            isLoading: false,
            data: []
        }),
    },
    PayoutInitialState
);
