import { ConfirmBoxInitialState } from "./../states";
import { handleActions } from "redux-actions";
import { IConfirmBoxModel } from "../../interfaces";
import { ConfirmBoxActions } from "../actions";

export const confirmBoxReducer = handleActions<
  IConfirmBoxModel,
  IConfirmBoxModel
>(
  {
    [ConfirmBoxActions.TOGGLE_CONFIRM_BOX]: (
      state = ConfirmBoxInitialState,
      action
    ): IConfirmBoxModel => ({
      ...action.payload,
    }),
  },
  ConfirmBoxInitialState
);
