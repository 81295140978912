import { Logic, createLogic } from "redux-logic";
import { push } from "react-router-redux";
import { LoginLogics } from "./Login";
import { SingupLogics } from "./Signup";
import { PostTypesLogics } from "./PostTypes";
import { CategoryLogics } from "./Category";
import { PostLogics } from "./Post";
import { profileLogics } from "./Profile";
import { PaymentMethodLogics } from "./PaymentMethod";
import { PayoutLogics } from "./CreatePayout";
import { DashboardLogics } from "./Dashboard";
import { EarningsLogics } from "./Earnings";
import { IdentityVerificationLogics } from "./IdentityVerification";

export const redirectToLogic: Logic = createLogic({
  type: "REDIRECT_TO",
  cancelType: "REDIRECT",
  latest: true,
  process: async (data, dispatch: any, done) => {
    const action: any = data.action;
    dispatch(push(action.payload.path));
    done();
  },
});

export const AllLogics: Logic[] = [
  ...LoginLogics,
  ...SingupLogics,
  ...profileLogics,
  ...PostTypesLogics,
  ...CategoryLogics,
  ...PostLogics,
  ...PaymentMethodLogics,
  ...PayoutLogics,
  ...DashboardLogics,
  ...EarningsLogics,
  ...IdentityVerificationLogics,
  redirectToLogic,
];
