import { createAction } from "redux-actions";

export enum PostActions {
  REQUEST_ADD_POST = "Request add post details!",
  ADD_POST_SUCCESS = "Post added successfully!",
  ADD_POST_FAILED = "Post added failed!",
  REQUEST_GET_POST = "Request GET post details!",
  GET_POST_SUCCESS = "Post GET successfully!",
  GET_POST_FAILED = "Post GET failed!",
  REQUEST_POST_DETAILS = "Request GET post details by slug!",
  POST_DETAILS_SUCCESS = "Post details successfully!",
  POST_DETAILS_FAILED = "Post details failed!",
  REQUEST_POST_DELETE = "Delete Post!",
  POST_DELETE_SUCCESS = "Post details successfully!",
}

export const requestAddPost = createAction(PostActions.REQUEST_ADD_POST);
export const postAddSuccess = createAction(PostActions.ADD_POST_SUCCESS);
export const postAddFailed = createAction(PostActions.ADD_POST_FAILED);

export const requestGetPost = createAction(PostActions.REQUEST_GET_POST);
export const postGetSuccess = createAction(PostActions.GET_POST_SUCCESS);
export const postGetFailed = createAction(PostActions.GET_POST_FAILED);

export const requestPostDetails = createAction(
  PostActions.REQUEST_POST_DETAILS
);
export const postDetailsSuccess = createAction(
  PostActions.POST_DETAILS_SUCCESS
);
export const postDetailsFailed = createAction(PostActions.POST_DETAILS_FAILED);

export const requestPostDelete = createAction(PostActions.REQUEST_POST_DELETE);
export const postDeleteSuccess = createAction(PostActions.POST_DELETE_SUCCESS);
