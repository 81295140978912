import React from "react";
import { Provider } from "react-redux";
import { Router as BrowserRouter } from "react-router-dom";
import store,{ history }  from "./redux";
import Routes from "./routes";
import "./scss/index.scss";

function App() {
  return (
    <Provider store={store}>
      <BrowserRouter history={history}>
        <Routes />
      </BrowserRouter>
    </Provider>
  );
}

export default App;
