import { createLogic, Logic } from "redux-logic";
import {
  showToastr,
  redirectTo,
  PostActions,
  postAddFailed,
  postAddSuccess,
  postGetFailed,
  postGetSuccess,
  postDetailsFailed,
  postDetailsSuccess,
  requestCategoryList,
} from "../actions";
import { RouteURLs } from "../../routes/routes";
import { ApiHelper } from "../../utills/helpers";
import { ApiRoutes } from "../../utills/constants/ApiRoutes";
import { IRootState } from "../../interfaces";

let getPostAPI: any;
/**
 *
 */
const addPostLogic: any = createLogic({
  type: PostActions.REQUEST_ADD_POST,
  latest: true,
  async process({ action }, dispatch, done) {
    const payload = (action as any).payload;
    const postData = new FormData();
    for (const key in payload) {
      if (payload.hasOwnProperty(key)) {
        const element = payload[key];
        if (
          ["description", "categories", "tags"].indexOf(key) > -1 &&
          typeof element !== "string"
        ) {
          postData.append(key, JSON.stringify(element));
        } else {
          postData.append(key, element);
        }
      }
    }
    const { isError, messages } = await new ApiHelper().FetchFromServer(
      ApiRoutes.ADD_POST.SERVICE,
      payload._id
        ? `${ApiRoutes.EDIT_POST.ENDPOINT.replace(":id", payload._id)}`
        : ApiRoutes.ADD_POST.ENDPOINT,
      payload._id ? ApiRoutes.EDIT_POST.METHOD : ApiRoutes.ADD_POST.METHOD,
      ApiRoutes.ADD_POST.AUTHENTICATE,
      undefined,
      postData,
      {
        headers: {
          "Content-Type": false,
        },
      }
    );
    if (isError) {
      dispatch(postAddFailed());
      dispatch(
        showToastr({
          isOpen: true,
          message: messages[0],
          type: "error",
        })
      );
      done();
      return;
    }
    dispatch(postAddSuccess());
    dispatch(
      showToastr({
        isOpen: true,
        message: messages[0],
        type: "success",
      })
    );
    dispatch(redirectTo({ path: RouteURLs.POSTS }));
    done();
  },
});
/**
 *
 */
const getPostLogic = createLogic({
  type: PostActions.REQUEST_GET_POST,
  latest: true,
  process: async ({ action }, dispatch, done) => {
    const payload = (action as any).payload;
    if (getPostAPI) {
      getPostAPI.cancelRequest();
    }
    getPostAPI = new ApiHelper();
    const { data, isError } = await getPostAPI.FetchFromServer(
      ApiRoutes.GET_POSTS.SERVICE,
      ApiRoutes.GET_POSTS.ENDPOINT,
      ApiRoutes.GET_POSTS.METHOD,
      ApiRoutes.GET_POSTS.AUTHENTICATE,
      payload
    );
    if (isError) {
      dispatch(postGetFailed());
      done();
      return;
    }
    dispatch(
      postGetSuccess({
        data: data.data,
        totalRecords: data.totalRecords,
      })
    );
    done();
  },
});
/**
 *
 */
const getPostDetailLogic = createLogic({
  type: PostActions.REQUEST_POST_DETAILS,
  latest: true,
  process: async ({ action }, dispatch, done) => {
    const payload = (action as any).payload;
    const { data, isError } = await new ApiHelper().FetchFromServer(
      ApiRoutes.GET_POST_DETAILS.SERVICE,
      ApiRoutes.GET_POST_DETAILS.ENDPOINT.replace(
        ":slug",
        payload.slug
      ).replace(":id", payload.id),
      ApiRoutes.GET_POST_DETAILS.METHOD,
      ApiRoutes.GET_POST_DETAILS.AUTHENTICATE
    );
    if (isError) {
      dispatch(postDetailsFailed());
      done();
      return;
    }
    dispatch(
      requestCategoryList({
        id: data.data.postType,
      })
    );
    dispatch(
      postDetailsSuccess({
        editPostDetails: data.data,
      })
    );
    done();
  },
});
/**
 *
 */
const deletePostLogic = createLogic({
  type: PostActions.REQUEST_POST_DELETE,
  latest: true,
  process: async ({ action, getState }, dispatch, done) => {
    const { postReducer } = getState() as IRootState;
    const payload = (action as any).payload;
    const { isError, messages } = await new ApiHelper().FetchFromServer(
      ApiRoutes.DELETE_POST.SERVICE,
      ApiRoutes.DELETE_POST.ENDPOINT.replace(":id", payload),
      ApiRoutes.DELETE_POST.METHOD,
      ApiRoutes.DELETE_POST.AUTHENTICATE
    );
    if (isError) {
      dispatch(
        showToastr({
          isOpen: true,
          message: messages[0],
          type: "error",
        })
      );
      done();
      return;
    }
    const { totalRecords, data } = postReducer;
    data.splice(
      data.findIndex((d: any) => d._id === payload),
      1
    );
    dispatch(
      showToastr({
        isOpen: true,
        message: messages[0],
        type: "success",
      })
    );
    dispatch(
      postGetSuccess({
        data: data,
        totalRecords: totalRecords - 1,
      })
    );
    done();
  },
});
/**
 *
 */
export const PostLogics: Logic[] = [
  addPostLogic,
  getPostLogic,
  getPostDetailLogic,
  deletePostLogic,
];
