import { Store, createStore, applyMiddleware, Action } from "redux";
import { createLogicMiddleware } from "redux-logic";
import { routerMiddleware } from "react-router-redux";
import { History } from "history";
import { IRootState } from "../interfaces";
import logger from "redux-logger";
import { RootReducer } from "./reducers";
import { createBrowserHistory } from "history";
import { AllLogics } from "./logics";

const configureStore = (
  history: History,
  initialState?: IRootState
): Store<IRootState> => {
  const middlewares: any[] = [
    routerMiddleware(history),
    createLogicMiddleware(AllLogics),
  ];
  const isProd: boolean = process.env.NODE_ENV === "production";
  if (!isProd) {
    middlewares.push(logger);
  }
  const middleware: any = applyMiddleware(...middlewares);

  const store: Store<any, Action> = createStore(
    RootReducer as any,
    initialState as any,
    middleware
  ) as Store<IRootState>;

  return store;
};
export function omit<T extends object, K extends keyof T>(
  target: T,
  ...omitKeys: K[]
): Omit<T, K> {
  return (Object.keys(target) as K[]).reduce((res: any, key) => {
    if (!omitKeys.includes(key)) {
      res[key] = target[key];
    }
    return res;
  }, {} as Omit<T, K>);
}
export const history = createBrowserHistory();
console.log(history);
const store: Store = configureStore(history);
export default store;
