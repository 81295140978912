import { Logic, createLogic } from "redux-logic";
import {
  showToastr,
  toggleModals,
  PayoutActions,
  payoutSuccess,
  payoutFailed,
  requestTotalAvailableBalance,
  payoutListFailed,
  payoutListSuccess,
} from "../actions";
import { ApiHelper } from "../../utills/helpers";
import { ApiRoutes } from "../../utills/constants/ApiRoutes";
/**
 *
 */
const createPayoutLogic = createLogic({
  type: PayoutActions.REQUEST_CREATE_PAYOUT,
  process: async ({ action }, dispatch, done) => {
    const { isError, messages } = await new ApiHelper().FetchFromServer(
      ApiRoutes.CREATE_PAYOUT.SERVICE,
      ApiRoutes.CREATE_PAYOUT.ENDPOINT,
      ApiRoutes.CREATE_PAYOUT.METHOD,
      ApiRoutes.CREATE_PAYOUT.AUTHENTICATE,
      undefined,
      (action as any).payload
    );
    if (isError) {
      dispatch(payoutFailed({}));
      dispatch(
        showToastr({
          isOpen: true,
          message: messages[0],
          type: "error",
        })
      );
      done();
      return;
    }
    dispatch(
      showToastr({
        isOpen: true,
        message: "Fund transfer Successfully!",
        type: "success",
      })
    );
    dispatch(payoutSuccess({}));
    dispatch(
      toggleModals({
        showPaymentBoxModal: false,
      })
    );
    dispatch(requestTotalAvailableBalance());
    done();
  },
});
/**
 *
 */
const fetchPayoutsLogic = createLogic({
  type: PayoutActions.REQUEST_PAYOUT_LIST,
  process: async ({ action }, dispatch, done) => {
    const { isError, data } = await new ApiHelper().FetchFromServer(
      ApiRoutes.FETCH_PAYOUTS.SERVICE,
      ApiRoutes.FETCH_PAYOUTS.ENDPOINT,
      ApiRoutes.FETCH_PAYOUTS.METHOD,
      ApiRoutes.FETCH_PAYOUTS.AUTHENTICATE,
      undefined,
      (action as any).payload
    );
    if (isError) {
      dispatch(payoutListFailed({}));
      done();
      return;
    }
    dispatch(payoutListSuccess({data:data.data,totalRecords:data.totalRecords}));
    done();
  },
});
export const PayoutLogics: Logic[] = [
    createPayoutLogic as Logic,
    fetchPayoutsLogic as Logic
];
