import { createAction } from "redux-actions";

export enum ProfileActions {
  REQUEST_PROFILE_DETAILS = "Request user details!",
  PROFILE_DETAILS_FAILED = "Profile details failed!",
  PROFILE_DETAILS_SUCCESS = "Profile details success!",
  REQUEST_UPDATE_PROFILE = "Request update profile details!",
  TOGGLE_LOADING = "Toggle profile loader!",
  TOGGLE_UPDATE_LOADING = "Toggle profile update loader!",
}

export const requestProfileDetails = createAction(
  ProfileActions.REQUEST_PROFILE_DETAILS
);

export const profileDetailsFailed = createAction(
  ProfileActions.PROFILE_DETAILS_FAILED
);

export const profileDetailsSuccess = createAction(
  ProfileActions.PROFILE_DETAILS_SUCCESS
);

export const requestUpdateProfile = createAction(
  ProfileActions.REQUEST_UPDATE_PROFILE
);
export const toggleProfileLoader = createAction(ProfileActions.TOGGLE_LOADING);
export const toggleProfileUpdateLoader = createAction(ProfileActions.TOGGLE_UPDATE_LOADING);
