import { handleActions } from "redux-actions";
import { IPaymentMethodModal } from "../../interfaces";
import { PayoutMethodsActions } from "../actions";
import { PaymentMethodInitialState } from "./../states";

export const paymentMethodReducer = handleActions<
  IPaymentMethodModal,
  IPaymentMethodModal
>(
  {
    [PayoutMethodsActions.TOGGLE_LOADER]: (
      state = PaymentMethodInitialState,
      action
    ): IPaymentMethodModal => ({
      ...state,
      isLoading: action.payload.isLoading,
    }),
    [PayoutMethodsActions.TOGGLE_ADD_LOADER]: (
      state = PaymentMethodInitialState,
      action
    ): IPaymentMethodModal => ({
      ...state,
      isAdding: action.payload.isAdding,
    }),
    [PayoutMethodsActions.PAYOUT_METHOD_LIST_FAILED]: (
      state = PaymentMethodInitialState,
      action
    ): IPaymentMethodModal => ({
      ...state,
      isLoading: false,
      list: [],
    }),

    [PayoutMethodsActions.PAYOUT_METHOD_LIST_SUCCESS]: (
      state = PaymentMethodInitialState,
      action
    ): IPaymentMethodModal => ({
      ...state,
      isLoading: false,
      list: action.payload.list,
    }),
  },
  PaymentMethodInitialState
);
