import React from "react";
import CircularProgress from "@material-ui/core/CircularProgress";
import { makeStyles, Theme, createStyles } from "@material-ui/core";

const useStylesFacebook = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      position: "relative",
      height: "80vh",
    },
    bottom: {
      color: theme.palette.grey[theme.palette.type === "light" ? 200 : 700],
      position: "absolute",
      left: "49%",
      top: "49%",
    },
    top: {
      color: "#1a90ff",
      animationDuration: "550ms",
      position: "absolute",
      left: "49%",
      top: "49%",
    },
    circle: {
      strokeLinecap: "round",
    },
  })
);

const Loader = () => {
  const classes = useStylesFacebook();

  return (
    <>
      <div className={classes.root}>
        <CircularProgress
          variant="determinate"
          className={classes.bottom}
          size={40}
          thickness={2}
          value={100}
        />
        <CircularProgress
          variant="indeterminate"
          disableShrink
          className={classes.top}
          classes={{
            circle: classes.circle,
          }}
          size={40}
          thickness={2}
        />
      </div>
    </>
  );
};
const useStylesFacebookWidget = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      position: "relative",
    },
    bottom: {
      color: theme.palette.grey[theme.palette.type === "light" ? 200 : 700],
      position: "absolute",
      left: "49%",
      top: "49%",
    },
    top: {
      color: "#1a90ff",
      animationDuration: "550ms",
    },
    circle: {
      strokeLinecap: "round",
    },
  })
);
export const WidgetLoader = () => {
  const classes = useStylesFacebookWidget();

  return (
    <>
      <div className={classes.root}>
        <CircularProgress
          variant="determinate"
          className={classes.bottom}
          size={10}
          thickness={2}
          value={100}
        />
        <CircularProgress
          variant="indeterminate"
          disableShrink
          className={classes.top}
          classes={{
            circle: classes.circle,
          }}
          size={20}
          thickness={2}
        />
      </div>
    </>
  );
};
export default Loader;
