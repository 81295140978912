import { SignupInitialState } from "./../states";
import { handleActions } from "redux-actions";
import { ISignupModal } from "../../interfaces";
import { SignupActionTypes } from "../actions";

export const singupReducer = handleActions<ISignupModal, ISignupModal>(
  {
    [SignupActionTypes.REQUEST_USER_SIGNUP]: (
      state = SignupInitialState,
      action
    ): ISignupModal => ({
      ...state,
      isLoading: true,
    }),
    [SignupActionTypes.USER_SIGNUP_FAILED]: (
      state = SignupInitialState,
      action
    ): ISignupModal => ({
      ...state,
      isLoading: false,
    }),
    [SignupActionTypes.USER_SIGNUP_SUCCESS]: (
      state = SignupInitialState,
      action
    ): ISignupModal => ({
      ...state,
      isLoading: false,
    }),
  },
  SignupInitialState
);
