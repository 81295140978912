import { MainLayoutInitialState } from "./../states";
import { handleActions } from "redux-actions";
import { IMainLayoutModal } from "../../interfaces";
import { MainLayoutActions } from "../actions";

export const mainLayoutReducer = handleActions<
  IMainLayoutModal,
  IMainLayoutModal
>(
  {
    [MainLayoutActions.TOGGLE_SIDEBAR]: (
      state = MainLayoutInitialState,
      action
    ): IMainLayoutModal => ({
      ...action.payload,
    }),
  },
  MainLayoutInitialState
);
