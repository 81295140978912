import { IPostModel } from "../../interfaces";

export const PostInitialState: IPostModel = {
  isLoading: true,
  isPostAdding: false,
  data: [],
  totalRecords: 0,
  isDetailsLoading: false,
  editPostDetails: {
    title: "",
    description: "",
    shortDescription: "",
    postType: "",
    categories: [],
    tags: [],
    featuredImage: null,
  },
};
