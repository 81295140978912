import { handleActions } from "redux-actions";
import { IModal } from "../../interfaces";
import { ModalActions } from "../actions";
import { ModalInitialState } from "./../states";

export const modalReducer = handleActions<IModal, IModal>(
  {
    [ModalActions.SET_MODAL_STATE]: (
      state = ModalInitialState,
      action
    ): IModal => ({
      ...state,
      ...action.payload,
    }),
  },
  ModalInitialState
);
