import { createAction } from "redux-actions";

export enum SignupActionTypes {
  REQUEST_USER_SIGNUP = "Request Signup user!",
  USER_SIGNUP_FAILED = "User Signup failed!",
  USER_SIGNUP_SUCCESS = "User Signup success!",
}

export const requestSignup = createAction(
  SignupActionTypes.REQUEST_USER_SIGNUP
);

export const userSignupFailed = createAction(
  SignupActionTypes.USER_SIGNUP_FAILED
);

export const userSignupSuccess = createAction(
  SignupActionTypes.USER_SIGNUP_SUCCESS
);
