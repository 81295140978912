import React from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import { IConfirmBoxModel } from "../../../interfaces";
import { useDispatch } from "react-redux";
import { toggleConfirmBox } from "../../../redux/actions";
import { ConfirmBoxInitialState } from "../../../redux/states";
import { DialogContentText } from "@material-ui/core";
import Slide from "@material-ui/core/Slide";
import { TransitionProps } from "@material-ui/core/transitions";

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & { children?: React.ReactElement<any, any> },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});
const ConfirmBox = (props: IConfirmBoxModel) => {
  const { show, title, text, onCancel, onConfirm } = props;
  const dispatch = useDispatch();
  const onClose = () => {
    if (onCancel) {
      onCancel();
    }
    dispatch(toggleConfirmBox(ConfirmBoxInitialState));
  };
  return (
    <Dialog
      open={show}
      onClose={onClose}
      TransitionComponent={Transition}
      keepMounted
      aria-labelledby="confirm-dialog"
    >
      <DialogTitle id="confirm-dialog">{title}</DialogTitle>
      <DialogContent>
        <DialogContentText>{text}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          No
        </Button>
        <Button
          onClick={() => {
            onClose();
            if (onConfirm) {
              onConfirm();
            }
          }}
          color="primary"
        >
          Yes
        </Button>
      </DialogActions>
    </Dialog>
  );
};
export default ConfirmBox;
