import { createLogic, Logic } from "redux-logic";
import cookie from "js-cookie";
import {
  LoginActionTypes,
  userLoginFailed,
  userLoginSuccess,
  showToastr,
  redirectTo,
} from "../actions";
import { RouteURLs } from "../../routes/routes";
import { ApiHelper } from "../../utills/helpers";
import { AuthTokenKey, AuthTokenExpirationKey } from "../../utills/constants";
import { IRootState } from "../../interfaces";
import { ApiRoutes } from "../../utills/constants/ApiRoutes";

/**
 *
 */
const loginLogic: any = createLogic({
  type: LoginActionTypes.REQUEST_USER_LOGIN,
  latest: true,
  async process({ action, getState }, dispatch, done) {
    const { loginReducer } = getState() as IRootState;
    const credentials = (action as any).payload;
    const { data, isError, messages } = await new ApiHelper().FetchFromServer(
      ApiRoutes.LOGIN.SERVICE,
      ApiRoutes.LOGIN.ENDPOINT,
      ApiRoutes.LOGIN.METHOD,
      ApiRoutes.LOGIN.AUTHENTICATE,
      undefined,
      credentials
    );
    if (isError) {
      dispatch(userLoginFailed());
      dispatch(
        showToastr({
          isOpen: true,
          message: messages[0],
          type: "error",
        })
      );
      done();
      return;
    }
    localStorage.setItem(AuthTokenKey, data.token);
    localStorage.setItem(AuthTokenExpirationKey, data.expires);
    cookie.set("token", data.token, { expires: 1, path: '/', domain: process.env.REACT_APP_DOMAIN_NAME });
    cookie.set("tokenExpiry", data.expires, { expires: 1, path: '/', domain: process.env.REACT_APP_DOMAIN_NAME });
    dispatch(userLoginSuccess());
    dispatch(
      showToastr({
        isOpen: true,
        message: messages[0],
        type: "success",
      })
    );
    if (!loginReducer.reLoginRequired) {
      dispatch(redirectTo({ path: RouteURLs.DASHBOARD }));
    } else {
      window.location.reload();
    }
    done();
  },
});

const refreshLoginToken = createLogic({
  type: LoginActionTypes.REFRESH_TOKEN,
  process: async (action, disppatch, done) => {
    const { data, isError } = await new ApiHelper().FetchFromServer(
      ApiRoutes.REFRESH_TOKEN.SERVICE,
      ApiRoutes.REFRESH_TOKEN.ENDPOINT,
      ApiRoutes.REFRESH_TOKEN.METHOD,
      ApiRoutes.REFRESH_TOKEN.AUTHENTICATE
    );
    if (!isError) {
      localStorage.setItem(AuthTokenKey, data.token);
      localStorage.setItem(AuthTokenExpirationKey, data.expires);
      cookie.set("token", data.token, { expires: 1, path: '/', domain: process.env.REACT_APP_DOMAIN_NAME });
      cookie.set("tokenExpiry", data.expires, { expires: 1, path: '/', domain: process.env.REACT_APP_DOMAIN_NAME });
    }
    done();
  },
});

const verifyLoginToUserprtalReq = createLogic({
  type: LoginActionTypes.REQUEST_LOGIN_VERIFY,
  process: async ({ action }, dispatch, done) => {
    const { data, isError } = await new ApiHelper().FetchFromServer(
      ApiRoutes.VERIFY_LOGIN_REQUEST.SERVICE,
      ApiRoutes.VERIFY_LOGIN_REQUEST.ENDPOINT,
      ApiRoutes.VERIFY_LOGIN_REQUEST.METHOD,
      ApiRoutes.VERIFY_LOGIN_REQUEST.AUTHENTICATE
    );
    if (!isError) {
      localStorage.setItem(AuthTokenKey, data.token);
      localStorage.setItem(AuthTokenExpirationKey, data.expires);
      cookie.set("token", data.token, { expires: 1, path: '/', domain: process.env.REACT_APP_DOMAIN_NAME });
      cookie.set("tokenExpiry", data.expires, { expires: 1, path: '/', domain: process.env.REACT_APP_DOMAIN_NAME });
      dispatch(userLoginSuccess());
      const payloadData = (action as any).payload;
      let redirectionPath = RouteURLs.DASHBOARD
      if (payloadData.redirectTo === "profile") {
        redirectionPath = RouteURLs.MY_PROFILE
      }
      if (payloadData.redirectTo === "add-post") {
        redirectionPath = RouteURLs.ADD_POST
      }
      dispatch(redirectTo({ path: redirectionPath }));
    } else {
      cookie.remove("token", { path: '/', domain: process.env.REACT_APP_DOMAIN_NAME });
      cookie.remove("tokenExpiry", { path: '/', domain: process.env.REACT_APP_DOMAIN_NAME });
      localStorage.removeItem(AuthTokenKey);
      localStorage.removeItem(AuthTokenExpirationKey);
      dispatch(redirectTo({ path: RouteURLs.LOGIN }));

    }
    done();
  },
});

export const LoginLogics: Logic[] = [loginLogic, refreshLoginToken, verifyLoginToUserprtalReq];
