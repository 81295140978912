export const shortNumber = (num: number, requierdFixed = true): string => {
  return `${
    // Nine Zeroes for Billions
    Math.abs(num) >= 1.0e9
      ? requierdFixed
        ? (Math.abs(num) / 1.0e9).toFixed(2) + "Ar"
        : parseInt(`${Math.abs(num) / 1.0e9}`) + "Ar"
      : // Eigth Zeroes for Millions
      Math.abs(num) >= 1.0e5
      ? requierdFixed
        ? (Math.abs(num) / 1.0e5).toFixed(2) + "Cr"
        : parseInt(`${Math.abs(num) / 1.0e5}`) + "Cr"
      : // Five Zeroes for Millions
      Math.abs(num) >= 1.0e5
      ? requierdFixed
        ? (Math.abs(num) / 1.0e5).toFixed(2) + "L"
        : parseInt(`${Math.abs(num) / 1.0e5}`) + "L"
      : // Three Zeroes for Thousands
      Math.abs(num) >= 1.0e3
      ? requierdFixed
        ? (Math.abs(num) / 1.0e3).toFixed(2) + "K"
        : parseInt(`${Math.abs(num) / 1.0e3}`) + "K"
      : requierdFixed
      ? Math.abs(num).toFixed(2)
      : parseInt(`${Math.abs(num)}`)
  }`;
};

export const formatNumber = (
  value = 0,
  options: {
    currency?: string;
    minimumFractionDigits?: number;
    maximumFractionDigits?: number;
  } = {}
) => {
  const formattedNumber = new Intl.NumberFormat("en-IN", {
    style: "currency",
    currency: options.currency || "INR",
    minimumFractionDigits: options.minimumFractionDigits ?? 0,
    maximumFractionDigits: options.maximumFractionDigits ?? 0,
  });

  return formattedNumber.format(Number(value));
};
