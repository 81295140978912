import { createAction } from "redux-actions";

export enum PayoutMethodsActions {
  GET_PAYOUT_METHOD_LISTS = "Request payment methods!",
  PAYOUT_METHOD_LIST_SUCCESS = "Payment method list success!",
  PAYOUT_METHOD_LIST_FAILED = "Payment method list failed!",
  TOGGLE_LOADER = "Toggle Payoutmethod loader",
  TOGGLE_ADD_LOADER = "Toggle Payout method add status loader",
  REQUEST_ADD_PAYOUT_METHOD = "Request add payment method.",
  ADD_PAYOUT_METHOD_SUCCESS = "Requested add payment method success.",
  ADD_PAYOUT_METHOD_FAILED = "Requested add payment method failed.",
  ACTIVATE_ACCOUNT = "Activate fund account.",
  DEACTIVATE_ACCOUNT = "Deactivate fund account.",
}

export const requestPayoutMethods = createAction(
  PayoutMethodsActions.GET_PAYOUT_METHOD_LISTS
);

export const payoutMethodSuccess = createAction(
  PayoutMethodsActions.PAYOUT_METHOD_LIST_SUCCESS
);

export const payoutMethodFailed = createAction(
  PayoutMethodsActions.PAYOUT_METHOD_LIST_FAILED
);

export const requestAddPayoutMethods = createAction(
  PayoutMethodsActions.REQUEST_ADD_PAYOUT_METHOD
);

export const addPayoutMethodSuccess = createAction(
  PayoutMethodsActions.ADD_PAYOUT_METHOD_SUCCESS
);

export const addPayoutMethodFailed = createAction(
  PayoutMethodsActions.ADD_PAYOUT_METHOD_SUCCESS
);

export const togglePaymentMethodLoader = createAction(
  PayoutMethodsActions.TOGGLE_LOADER
);

export const toggleAddPaymentMethodLoader = createAction(
  PayoutMethodsActions.TOGGLE_ADD_LOADER
);

export const activateFundAccount = createAction(
  PayoutMethodsActions.ACTIVATE_ACCOUNT
);

export const deactivateFundAccount = createAction(
  PayoutMethodsActions.DEACTIVATE_ACCOUNT
);
