import { createAction } from "redux-actions";

export enum DashboardActions {
  GET_TOTAL_POST_VIEWS = "Request total post views!",
  TOTAL_POST_VIEWS_SUCCESS = "Total post views success!",
  TOTAL_POST_VIEWS_FAILED = "Total post views failed!",
  TOGGLE_LOADER = "Toggle postviews loader",
  GET_TOTAL_EARNINGS = "Request total earnings",
  TOTAL_EARNINGS_SUCCESS = "Total earnings success!",
  TOTAL_EARNINGS_FAILED = "Total earnings failed!",
  TOGGLE_EARNINGS_LOADER = "Toggle earnings loader",
  GET_TOTAL_POST = "Request total posts",
  TOTAL_POST_SUCCESS = "Total posts success!",
  TOTAL_POST_FAILED = "Total posts failed!",
  TOGGLE_POST_ANALYTICS_LOADER = "Toggle posts analytics loader",
  GET_TOTAL_AVAILABLE_BALANCE = "Request total available balance",
  TOTAL_AVAILABLE_BALANCE_SUCCESS = "Total Available balance success!",
  TOTAL_AVAILABLE_BALANCE_FAILED = "Total Available balance failed!",
  TOGGLE_AVAILABLE_BALANCE_ANALYTICS_LOADER = "Toggle Available balance analytics loader",
}

export const requestTotalPostViews = createAction(
  DashboardActions.GET_TOTAL_POST_VIEWS
);
export const totalPostViewsSuccess = createAction(
  DashboardActions.TOTAL_POST_VIEWS_SUCCESS
);
export const totalPostViewFailed = createAction(
  DashboardActions.TOTAL_POST_VIEWS_FAILED
);
export const togglePostViewsLoader = createAction(
  DashboardActions.TOGGLE_LOADER
);
// Earnings
export const requestTotalEarnings = createAction(
  DashboardActions.GET_TOTAL_EARNINGS
);
export const totalEarningsSuccess = createAction(
  DashboardActions.TOTAL_EARNINGS_SUCCESS
);
export const totalEarningsFailed = createAction(
  DashboardActions.TOTAL_EARNINGS_FAILED
);
export const toggleEarningsLoader = createAction(
  DashboardActions.TOGGLE_EARNINGS_LOADER
);
// Total Posts
export const requestTotalPosts = createAction(
  DashboardActions.GET_TOTAL_POST
);
export const totalPostsSuccess = createAction(
  DashboardActions.TOTAL_POST_SUCCESS
);
export const totalPostsFailed = createAction(
  DashboardActions.TOTAL_POST_FAILED
);
export const togglePostsAnalyticsLoader = createAction(
  DashboardActions.TOGGLE_POST_ANALYTICS_LOADER
);
// Total availableBalance
export const requestTotalAvailableBalance = createAction(
  DashboardActions.GET_TOTAL_AVAILABLE_BALANCE
);
export const totalAvailableBalanceSuccess = createAction(
  DashboardActions.TOTAL_AVAILABLE_BALANCE_SUCCESS
);
export const totalAvailableBalanceFailed = createAction(
  DashboardActions.TOTAL_AVAILABLE_BALANCE_FAILED
);
export const toggleAvailableBalanceAnalyticsLoader = createAction(
  DashboardActions.TOGGLE_AVAILABLE_BALANCE_ANALYTICS_LOADER
);