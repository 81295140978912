import { createLogic, Logic } from "redux-logic";
import { EarningsActions, earningsRecordsSuccess, earningsRecordsFailed, toggleEarningsRecordsLoader } from "../actions";
import { ApiRoutes } from "../../utills/constants/ApiRoutes";
import { ApiHelper } from "../../utills/helpers";

/**
 *
 */
const fetchEarningsLogic = createLogic({
    type: EarningsActions.GET_EARNINGS,
    latest: true,
    process: async ({ action }, dispatch, done) => {
        dispatch(toggleEarningsRecordsLoader(true));
      const payload = (action as any).payload;
      const { data, isError } = await new ApiHelper().FetchFromServer(
        ApiRoutes.GET_EARNINGS.SERVICE,
        ApiRoutes.GET_EARNINGS.ENDPOINT,
        ApiRoutes.GET_EARNINGS.METHOD,
        ApiRoutes.GET_EARNINGS.AUTHENTICATE,
        payload
      );
      if (isError) {
        dispatch(earningsRecordsFailed());
        done();
        return;
      }
      dispatch(
        earningsRecordsSuccess({
          data: data.earnings,
          totalRecords: data.totalRecords,
        })
      );
      done();
    },
  });

  export const EarningsLogics: Logic[] = [fetchEarningsLogic as Logic];