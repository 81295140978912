import { createAction } from "redux-actions";

export * from "./Login";
export * from "./Toastr";
export * from "./MainLayout";
export * from "./Signup";
export * from "./PostTypes";
export * from "./Category";
export * from "./Post";
export * from "./ConfirmBox";
export * from "./Profile";
export * from "./PayoutMethods";
export * from './CreatePayout';
export * from "./Modal";
export * from "./Dashboard";
export * from "./Earnings";
export * from './IdentityVerification';

export const redirectTo = createAction("REDIRECT_TO");
